
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { FormItemInfo } from "../interface/form";
import { SEARCH_ITEM_CRITERIA } from "../interface/searchCriteria";

@Component
export default class CustomForm extends Vue {
  @Prop() formArray!: FormItemInfo[];
  @Prop() actionText: string;
  form: SEARCH_ITEM_CRITERIA | unknown;

  created(): void {
    this.createForm();
  }

  createForm(): void {
    this.form = this.formArray.reduce((acc, item) => {
      acc = { ...acc, [item.name]: item.value };
      return acc;
    }, {});
  }

  @Watch("formArray", { immediate: false, deep: true })
  onFormDataChange(): any {
    this.$emit("onChange", this.formArray);
    return this.formArray;
  }

  onSubmit(): void {
    this.createForm();
    this.$emit("onSubmit", this.form);
  }
}
